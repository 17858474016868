import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [{
            path: "/Login",
            name: "Login",
            component: () =>
                import ('./components/Auth/login'),
            meta: { requiresAuth: false }

        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: '/',
            redirect: '/dashboard',
            component: () =>
                import ('@/layouts/Layout'),
            meta: { requiresAuth: true },
            children: [
                // Components

                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: () =>
                        import ('./components/Views/Dashboard/basicDashboard'),
                },
                {
                    path: "/banner",
                    name: "banner",
                    props: true,
                    component: () =>
                        import ("./components/Views/Home/banner"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/category",
                    name: "category",
                    props: true,
                    component: () =>
                        import ("./components/Views/Category/category"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/subcategory",
                    name: "subcategory",
                    props: true,
                    component: () =>
                        import ("./components/Views/Subcategory/subcategory"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/subcategoryView",
                    name: "subcategoryView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Subcategory/subcategoryView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/categoryView",
                    name: "categoryView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Category/categoryView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/courses",
                    name: "courses",
                    props: true,
                    component: () =>
                        import ("./components/Views/Course/courses"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/courseView",
                    name: "courseView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Course/courseView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/moduleView",
                    name: "moduleView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Course/moduleView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/question",
                    name: "question",
                    props: true,
                    component: () =>
                        import ("./components/Views/Course/Questions/question"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/questionView",
                    name: "questionView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Course/Questions/questionView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/about",
                    name: "about",
                    props: true,
                    component: () =>
                        import ("./components/Views/About/about"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/userList",
                    name: "userList",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/userDetails",
                    name: "userDetails",
                    props: true,
                    component: () =>
                        import ("./components/Views/User/userDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/enquiryList",
                    name: "enquiryList",
                    props: true,
                    component: () =>
                        import ("./components/Views/Enquiry/enquiryList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/enquiryView",
                    name: "enquiryView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Enquiry/enquiryView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/enrollUsers",
                    name: "enrollUsers",
                    props: true,
                    component: () =>
                        import ("./components/Views/EnrollUsers/enrollUsers"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/examData",
                    name: "examData",
                    props: true,
                    component: () =>
                        import ("./components/Views/Exams/examData"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/examListMain",
                    name: "examListMain",
                    props: true,
                    component: () =>
                        import ("./components/Views/Exams/examListMain"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/evaluation",
                    name: "evaluation",
                    props: true,
                    component: () =>
                        import ("./components/Views/Exams/evaluation"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/answerView",
                    name: "answerView",
                    props: true,
                    component: () =>
                        import ("./components/Views/Exams/answerView"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/examRules",
                    name: "examRules",
                    props: true,
                    component: () =>
                        import ("./components/Views/Exams/examRules"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/purchaseList",
                    name: "purchaseList",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/purchaseList"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/purchaseDetails",
                    name: "purchaseDetails",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/purchaseDetails"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/paymentReport",
                    name: "paymentReport",
                    props: true,
                    component: () =>
                        import ("./components/Views/Purchase/paymentReport"),
                    meta: {
                        requiresAuth: false,
                    },
                },
            ]
        },

    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('token')) {
        var newURL = ''
        if (localStorage.getItem('role') == 'employee')
            newURL = "/authenticate/user";

        else
            newURL = "authenticate/admin";
        axios({
                method: "POST",
                url: newURL,
                headers: {
                    "token": localStorage.getItem("token")
                }
            })
            .then(response => {
                if (!response.data.status) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    store.commit("logoutUser");
                    store.commit("sessionOut", true)
                    next({
                        name: 'Login'
                    })
                    return
                }
            })
    }

    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        next({
            name: 'Login'
        })
        return
    }
    if (to.path === '/Login' && store.state.isLoggedIn) {
        next({
            path: '/'
        })
        return
    }
    next()
})

export default router