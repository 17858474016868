import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        category: null,
        categoryId: null,
        itemsPerPage: null,
        sellerPageFilters: {},
        SidebarBg: '',
        currentPage: null,
        isLoggedIn: !!localStorage.getItem('token'),
        userType: localStorage.getItem('userType'),
        userData: {},
    },
    mutations: {

        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
            if (state.userType == 'Admin') router.push('/')

        },

        logoutUser(state) {
            // state.isLoggedIn = false
            // state.isLog = false
            axios({
                method: "post",
                url: "/logout",
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data.status) {
                    state.isLoggedIn = false
                    state.isLog = false
                    localStorage.removeItem("role");
                    localStorage.removeItem("agentCode");
                    localStorage.removeItem("token");
                    localStorage.removeItem('isLoggedIn');
                    state.isLoggedIn = "";
                    router.push({ path: "/Login" });
                    // if (window.location.pathname != '/Login') {
                    //     this.$router.push('/Login')
                    // }
                }
            })
        },
        userCategory(state, item) {
            state.category = item.name
            state.categoryId = item._id
            localStorage.setItem("category", item.name);
            localStorage.setItem("categoryId", item._id);
        },
        changeCurrentPage(state, item) {
            state.currentPage = item
        },
        userData(state, payload) {
            state.userData = payload
            state.email = state.userData.email
        },
        userType(state, payload) {
            localStorage.setItem("userType", payload);
            state.userType = payload
        },
        sessionOut(state) {
            state.itemsPerPage = null
            localStorage.removeItem("token");
            localStorage.removeItem('isLoggedIn');
            state.currentPage = null
            state.isLoggedIn = false
            router.push({ path: "/Login" });
        }
    },
    actions: {

    }
})