import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import './plugins/base'
import ImageLoader from './components/Common/imageLoader'
Vue.component('ImageLoader', ImageLoader)

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)

// import Viewer from 'v-viewer'
// Vue.use(Viewer)
// import 'viewerjs/dist/viewer.css'
import ServerError from './components/Common/500'
Vue.component('ServerError', ServerError)

window.axios = require('axios')

// Vue.prototype.mediaUURL = "http://192.168.54.202:3200/file/get/"
// axios.defaults.baseURL = "http://192.168.54.202:3200";
// Vue.prototype.mediaURL = "http://192.168.54.202:3200/wp/";
// Vue.prototype.ipURL = "http://192.168.54.202:3200"

// Vue.prototype.mediaUURL = "http://3.27.140.132/file/get/"
// axios.defaults.baseURL = "http://3.27.140.132";
// Vue.prototype.mediaURL = "http://3.27.140.132/wp/";
// Vue.prototype.ipURL = "http://3.27.140.132"

Vue.prototype.mediaUURL = "https://api.bgfsafety.com/file/get/"
axios.defaults.baseURL = "https://api.bgfsafety.com";
Vue.prototype.mediaURL = "https://api.bgfsafety.com/wp/";
Vue.prototype.ipURL = "https://api.bgfsafety.com"

axios.defaults.timeout = 60000;



Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,

    render: h => h(App)
}).$mount('#app')